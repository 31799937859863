import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import styled from "styled-components";

const Header = styled.div`
  .landing-page-image {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .landing-page-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;
const images = [
  `url(${process.env.PUBLIC_URL}/img/cleaning-cover-a.jpg)`,
  `url(${process.env.PUBLIC_URL}/img/cleaning-cover-b.jpg)`,
];
export const LandingPage = (props) => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Header>
      <div
        className="landing-page-image"
        style={{
          backgroundImage: currentImage,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="landing-page-header">
            <a href="/" className="company-name">
              Glanz24
            </a>
          </div>
          <div className="landing-page-text">
            <h1 id="landingImagetitle">{props.data.firmName}</h1>
            <p id="landingImagetext1">{props.data.tagLine1}</p>
            <p id="landingImagetext2">{props.data.tagLine2}</p>
          </div>
          <div className="arrow-down-container">
            <a href="#servicesBar" className="arrow-down-link">
              <FaAngleDown className="fa fa-chevron-down" />
            </a>
          </div>
        </div>
      </div>
      {/* <div id="content" className="landing-page-content">
        
      </div> */}
    </Header>
  );
};
