import Divider from "../components/Divider";
import styled from "styled-components";
export const Map = (props) => {
  const ImportantText = styled.p`
    color: black;
    margin-left: -35rem;
    font-size: 1rem;
    @media (min-width: 0px) and (max-width: 766px) {
      margin-left: 1.5rem;
      font-weight: 300;
    }
  `;
  const LocationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: "wrap";
    @media (min-width: 0px) and (max-width: 766px) {
      flex-direction: column;
      align-items: center;
    }
  `;
  const MapWraper = styled.div`
    width: 95%;
    flex: 1;
    padding-left: 1px;
    .map-iframe {
      border: 0;
      margin-top: 2.5rem;
      margin-bottom: 3rem;
      margin-left: 9rem;
      @media (min-width: 0px) and (max-width: 766px) {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 330;
        height: 250;
      }
    }
  `;
  const MapAddress = styled.p`
    flex: 1;
    color: black;
    margin-left: 3rem;
    margin-top: 3.5rem;
    font-family: "Lato";
    margin-left: -55rem;
    font-size: 1rem;
    @media (min-width: 0px) and (max-width: 766px) {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      margin-bottom: 1rem;
    }
  `;
  return (
    <>
      <ImportantText>
        <b>{props.data ? props.data.imp_msg : "loading..."}</b>
        {props.data ? props.data.imp_p : "loading..."}
      </ImportantText>
      <Divider />
      <LocationContainer>
        <MapWraper>
          <iframe
            title="mapIframe"
            className="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5364.006944932777!2d8.837334!3d47.761987!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a7d3f1b5adfa1%3A0x72b1232c48fe582f!2sErzbergerstra%C3%9Fe%2014%2C%2078224%20Singen%20(Hohentwiel)%2C%20Germany!5e0!3m2!1sen!2sus!4v1680516988164!5m2!1sen!2sus"
            allowfullscreen=""
            loading="lazy"
            width="475"
            height="275"
            aria-hidden="false"
            tabindex="0"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapWraper>
        <MapAddress>
          Adresse:
          <br />
          <br />
          Erzbergerstrasse 14
          <br />
          78224 Singen ( Hohentwiel)
        </MapAddress>
      </LocationContainer>
      <Divider />
    </>
  );
};
