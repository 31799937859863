import "../../src/App.css";

export const Imprint = (props) => {
  const rawHtml = `
<style>
  [data-custom-class='body'], [data-custom-class='body'] * {
          background: transparent !important;
        }
  [data-custom-class='title'], [data-custom-class='title'] * {
            font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
          }
  [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
          }
  [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
          }
  [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
            font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
          }
  [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  text-align: center;
          }
  [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
          }
</style>

<div data-custom-class="body">
  <div><strong><span style="font-size: 26px;"><span data-custom-class="title">Impressum</span></span></strong></div>
  <div><br></div>
  <div>
    <span style="color: rgb(127, 127, 127);">
        <strong>
          <span style="font-size: 15px;">
              <span data-custom-class="subtitle">
                letzte Aktualisierung:
                <bdt class="question">May 21, 2023</bdt>
              </span>
          </span>
        </strong>
    </span>
  </div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div style="line-height: 1.5;">
    <span style="color: rgb(127, 127, 127);">
        <span style="color: rgb(89, 89, 89); font-size: 15px;">
          <span data-custom-class="body_text">
            Glanz 24 GmbH
          </span>
          <div><br></div>
          <span data-custom-class="body_text">
            Poststraße 2-4
          </span>
          <div><br></div>
          <span data-custom-class="body_text">
            78224 Gottmadingen
          </span>
          <div><br></div>
          <div><br></div>
          <span data-custom-class="body_text">
           Geschäftsführer: A Waheed
          </span>
          <div><br></div>
          <span data-custom-class="body_text">
            E-Mail: glanzgmbh1@gmail.com
          </span>
          <div><br></div>
          <span data-custom-class="body_text">
            Phone: +49 160 95954004
          </span>
          <div><br></div>
          <div><br></div>
          <span data-custom-class="body_text">
            Tax-ID entsprechend Sect. 27a: DE 340191169
          </span>
          <div><br></div>
          <span data-custom-class="body_text">
            Verantwortlich für den Inhalt acc. § 18 s. 2 MStV: A Waheed
          </span>
          <div><br></div>
          <div><br></div>
          <span data-custom-class="body_text">
            Haftungshinweis: Für die Inhalte externer Links übernehmen wir keine Haftung. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
          </span>
          <div><br></div>
          <div><br></div>
        </span>
    </span>
  </div>
</div>`;

  return (
    <div>
      <div className="back-to-home">
        <a href="/" className="back-to-home-a">
          Glanz24
        </a>
      </div>
      <div id="imprint" dangerouslySetInnerHTML={{ __html: rawHtml }} />
    </div>
  );
};
