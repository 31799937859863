import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { Footer } from "./pages/Footer";
import landingPageData from "./data/data.json";
import {Privacy} from './pages/privacy';
import {Imprint} from './pages/imprint';

const Layout = () => (
  <>
    <Outlet/>
    <Footer data={landingPageData.Footer}/>
  </>
);

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/imprint",
        element: <Imprint />
      }
    ]
  }
]);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={routers} />
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
