import "react-modal-overlay/dist/index.css";
import { useNavigate } from "react-router-dom";

export const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  let navigate = useNavigate();
  return (
    <div id="footer">
      <div className="container text-left">
        <p>
          &copy; {currentYear} {props.data && props.data.copyright}{" "}
          {/* <a href='http://www.templatewire.com' rel='nofollow'>
              TemplateWire
            </a> */}
        </p>
      </div>
      <div className="container text-right" id="legalSection">
        <span
          className="privacyItem"
          onClick={() => {
            navigate("/privacy");
          }}
        >
          {props.data && props.data.privacyPolicy}
        </span>
        <span
          className="privacyItem"
          onClick={() => {
            navigate("/imprint");
          }}
        >
          {props.data && props.data.imprint}
        </span>
      </div>
    </div>
  );
};
