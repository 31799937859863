import React from "react";
import styled from "styled-components";
const Divider = () => {
  const Divider = styled.div`
    height: 0.5px;
    width: 80%;
    margin-left: 10%;
    background-color: grey; 
  `;
  return <Divider></Divider>;
};

export default Divider;
