import Divider from "../components/Divider";
import styled from "styled-components";
export const Contact = (props) => {
  const ContactSection = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgb(54, 54, 54);
  `;
  const ContactDetails = styled.p`
    font-size: 0.9rem;
  `;
  const ContactSectionDetails = styled.div`
    color: white;
    background-color: rgb(54, 54, 54);
    text-align: center;
    display: inline-block;
    padding: 1.5rem 0rem;
  `;

  return (
    <ContactSection>
      <ContactSectionDetails>
        <ContactDetails>
          <strong>
            {props.data ? props.data.officeHoursHeading : "loading..."}
          </strong>
        </ContactDetails>
        <ContactDetails>
          <strong>{props.data ? props.data.officeHours1 : "loading..."}</strong>
        </ContactDetails>
        <ContactDetails>
          <strong>{props.data ? props.data.officeHours2 : "loading..."}</strong>
        </ContactDetails>
        <ContactDetails>
          <strong>{props.data ? props.data.officeHours3 : "loading..."}</strong>
        </ContactDetails>
      </ContactSectionDetails>

      <Divider />

      <ContactSectionDetails>
        <ContactDetails>
          <strong>
            {props.data ? props.data.contactHeading : "loading..."}
          </strong>
        </ContactDetails>
        <ContactDetails>
          <strong>{props.data ? props.data.contact1 : "loading..."}</strong>
        </ContactDetails>
        <ContactDetails>
          <strong>{props.data ? props.data.contact2 : "loading..."}</strong>
        </ContactDetails>
      </ContactSectionDetails>
    </ContactSection>
  );
};
