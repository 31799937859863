import { useState, useEffect } from "react";
import {LandingPage} from "./pages/landing";
import landingPageData from "./data/data.json";
import { Contact } from "./pages/Contact";
import { ServicesBar } from "./pages/ServicesBar";
import { Map } from "./pages/Map";
import { Services } from "./pages/Services";
import "./App.css";


const App = () => {
  return (
    <div>
      <LandingPage data={landingPageData.Header}/>
      <ServicesBar/>
      <Services data = {landingPageData.Services}/>
      <Map data={landingPageData.Map} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
export default App;
