import styled from "styled-components";
export const Services = (props) => {
  const ServiceContainer = styled.section`
    background-color: white;
    display: row;
    justify-content: space-between;
    margin-left: 11rem;
    color: black;
    @media (min-width: 0) and (max-width: 766px) {
      flex-direction: column;
      margin-left: 1;
      margin-right: 1rem;
      margin-top: 1rem;
      text-align: center;
      justify-content: space-between;
    }
  `;
  const GalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33.333%, 1fr));
    align-items: center;
    margin-left: 9rem;
    margin-right: 9rem;
    margin-top: 5rem;
    justify-content: center;
    margin-bottom: 5rem;
    @media (min-width: 0px) and (max-width: 766px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
      flex-direction: row;
      margin-left: 0.3rem;
    }
    .gallery-image {
      margin-top: 2rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      @media (min-width: 0px) and (max-width: 766px) {
        width: 250;
      }
    }
    .gallery-paragraph {
      padding: 0.5rem;
      display: inline;
      color: black;
      text-align: center;
      font-family: "Lato";
      font-weight: 500;
    }
    .gallery-paragraph p {
      font-size: 1rem;
    }
  `;
  return (
    <>
      <ServiceContainer>
        {props.data &&
          props.data.list_a.map((listItem) => {
            return (
              <ul>
                <li>{listItem.firstList}</li>
              </ul>
            );
          })}
      </ServiceContainer>

      <GalleryContainer className="gallery-container">
        {props.data &&
          props.data.list.map((listItem) => {
            return (
              <div>
                <section className="gallery-image">
                  <img src={listItem.img} />
                </section>
                <section className="gallery-paragraph">
                  <p>{listItem.name}</p>
                </section>
              </div>
            );
          })}
      </GalleryContainer>
    </>
  );
};
