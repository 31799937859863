import styled from "styled-components";
export const ServicesBar = () => {
  const ServiceBar = styled.div`
    background-color: rgb(54, 54, 54);
    width: 100%;
    height: 6rem;
    color: white;
    text-align: center;
    font-size: 3rem;
    font-family: "Lato", "sans-serif";
    padding-top: 2rem;
    @media (min-width: 0) and (max-width: 766px) {
      font-size: 2rem;
      width: 375;
    }
  `;
  return <ServiceBar id="servicesBar"> Was können wir für sie tun?</ServiceBar>;
};
